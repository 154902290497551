<template>
  <b-card
    :title="$t(title)"
    class="no-pointer"
  >

    <b-button
      v-if="info"
      :id="`popover-button-${info}`"
      v-ripple.400="'rgba(0, 207, 232, 0.15)'"
      variant="outline-info"
      class="mb-1"
    >
      i
    </b-button>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :theme="themeColor"
      :line-numbers="lineNumbers"
      :sort-options="{
        enabled: sortable,
      }"
      :row-style-class="rowStyleClassFn"
      @on-row-click="onRowClick"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'full_name'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
            variant="white"
          >
            <b-img
              :src="appLogo"
              alt="avatar"
              height="35"
            />
          </b-avatar>
          <span class="text-nowrap">{{ props.row.full_name }}</span>
        </span>

        <span v-else-if="props.column.field === 'rank'">
          <span v-if="props.formattedRow[props.column.field] === 1">
            <b-img
              :src="require('@/assets/images/icons/gold.svg')"
              height="35"
              alt="Medal Pic"
            />
          </span>
          <span v-else-if="props.formattedRow[props.column.field] === 2">
            <b-img
              :src="require('@/assets/images/icons/silver.svg')"
              height="35"
              alt="Medal Pic"
            />
          </span>
          <span v-else-if="props.formattedRow[props.column.field] === 3">
            <b-img
              :src="require('@/assets/images/icons/bronze.svg')"
              height="35"
              alt="Medal Pic"
            />
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'player_actions'">
          <b-button
            variant="danger"
            class="btn-icon"
            @click.stop="$emit('deletePlayerFromGroup', props.row.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </span>
        <span v-else-if="props.column.field === 'player_links'">
          <b-button
            variant="primary"
            class="btn-icon"
            @click.stop="$router.push({ name: 'dashboard-player', params: { playerId: props.row.id } })"
          >
            <feather-icon icon="LogInIcon" />
          </b-button>
        </span>

        <span v-else-if="props.column.field === 'email'">
          <b-button
            v-if="!props.formattedRow[props.column.field]"
            v-b-modal.modal-invite
            variant="primary"
            class="btn-icon"
            @click="inviteId = props.row.redeem_code"
          >
            {{ $t('Invite') }}
          </b-button>
          <span
            v-else-if="!props.row.approve_date"
          >
            <div class="d-flex">
              <b-button
                variant="danger"
                class="btn-icon mr-1"
              >
                X
              </b-button>&nbsp;{{ props.formattedRow[props.column.field] }}
            </div>
          </span>
          <span
            v-else
          >
            {{ props.formattedRow[props.column.field] }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'group_player_actions'">
          <b-button
            variant="danger"
            class="btn-icon"
            @click.stop="$emit('deletePlayerFromGroup', props.row.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </span>

        <!-- Column: Common -->
        <span
          v-else
          class="text-nowrap"
        >
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div>
            <b-pagination
              v-if="props.total > pageLength"
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <div slot="emptystate">
        {{ $t('No data available') }}
      </div>

    </vue-good-table>

    <b-modal
      id="modal-invite"
      v-model="modalShow"
      content-class="shadow"
      title="Invite"
      no-fade
      centered
      ok-title="Send"
      @ok.prevent="invite"
    >

      <b-form-group>
        <label for="email">{{ $t('Email') }}:</label>
        <b-form-input
          id="email"
          v-model="inviteEmail"
          type="email"
          :placeholder="$t('Email Address')"
        />
      </b-form-group>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BAvatar, BPagination, BCard, BImg, BButton, VBPopover, BModal,
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import logoDark from '@/assets/images/logo/avatar_light.svg'
import logoLight from '@/assets/images/logo/avatar_dark.svg'
import axios from '@axios'

export default {
  components: {
    BImg,
    BCard,
    VueGoodTable,
    BAvatar,
    BButton,
    BPagination,
    BModal,
    BFormGroup,
    BFormInput,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  emits: ['deletePlayerFromGroup'],
  props: {
    info: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      default: 'Table Title',
    },
    pageLength: {
      type: Number,
      default: 5,
    },
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    lineNumbers: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    searchTerm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inviteEmail: '',
      inviteId: '',
      modalShow: false,
    }
  },
  computed: {
    themeColor() {
      if (store.state.appConfig.layout.skin === 'light') {
        return ''
      }
      return 'nocturnal'
    },
    appLogo() {
      if (store.state.appConfig.layout.skin === 'light') {
        return logoDark
      }
      return logoLight
    },
  },
  methods: {
    /* eslint-disable */
    onRowClick(params) {
      // store.commit('spider/selectPlayer', params.row.id)
    },
    rowStyleClassFn(row) {
      // return row.id === store.state.spider.selectedPlayer ? 'highlight' : ''
    },
    invite() {
      axios.post(`/api/subscription/send-invite/`, {
        id: this.inviteId,
        mail: this.inviteEmail,
      }).then(() => {
        this.inviteEmail = null
        this.inviteId = null
        this.modalShow = false
      })
        .catch(() => {})
    }
  },
}
</script>

<style lang="scss">
td{
  vertical-align: middle !important;
}
.no-pointer .clickable{
  cursor: default !important;
}

th{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
