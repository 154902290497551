<template>
  <div style="height: inherit">

    <b-row>
      <b-col>
        <b-card>
          <b-form-input
            v-model="searchTerm"
            :placeholder="$t('Filter Players')"
          />
        </b-card>
      </b-col>
    </b-row>

    <spider-table
      v-if="!loading"
      :title="$t('Community')"
      :rows="rows"
      :columns="columns"
      :page-length="10"
      :search-term="searchTerm"
    />

    <div v-else>
      <b-row>
        <b-col>
          <b-overlay
            show
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <b-card
              title=""
              style="min-height:500px;"
            />
          </b-overlay>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BOverlay,
} from 'bootstrap-vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import SpiderTable from '../../components/SpiderTable.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    SpiderTable,
    BOverlay,
  },
  data() {
    return {
      loading: true,
      searchTerm: '',
    }
  },
  computed: {
    rows() {
      return store.state.spider.players
    },
    columns() {
      return [
        {
          label: '',
          field: 'rank',
          width: '5%',
        },
        {
          label: this.$t('Name'),
          field: 'full_name',
          width: '45%',
        },
        {
          label: this.$t('NBR Rounds'),
          field: 'nbrounds',
          width: '15%',
        },
        {
          label: this.$t('Spider HCP'),
          field: 'sghandicap',
          formatFn: this.formatHandicap,
          width: '15%',
        },
        {
          label: this.$t('Dashboard'),
          field: 'player_links',
          width: '10%',
          tdClass: 'text-center',
        },
      ]
    },
  },
  created() {
    store.dispatch('spider/updatePlayers').then(() => {
      this.loading = false
    })
      .catch(() => {
        this.loading = false
        // this.$toast({
        //   component: ToastificationContent,
        //   position: 'top-right',
        //   props: {
        //     title: 'Connection probleme',
        //     icon: 'SlashIcon',
        //     variant: 'danger',
        //     text: 'We couldn\t get your data please refresh the page.',
        //   },
        // })
      })
  },
  methods: {
    formatHandicap(score) {
      return score === 1000 ? '-' : (Math.round(score * 10) / 10).toFixed(1)
    },
  },
}
</script>

<style lang="scss">
</style>
